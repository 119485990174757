
import { defineComponent, ref } from "vue";
import { closeDialog } from "vue3-promise-dialog";
import AppAlert from "@/components/common/AppAlert.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppInput from "@/components/common/AppInput.vue";
import AppModal from "@/components/common/AppModal.vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "SaveRowModal",
    components: { AppAlert, AppButton, AppInput, AppModal, AppIcon },
    setup() {
        const saveRowName = ref("");
        return {
            saveRowName,
            closeDialog
        };
    }
});
