
import { defineComponent } from "vue";
import AppSection from "@/components/common/AppSection.vue";

export default defineComponent({
    name: "SettingsLegalPage",
    components: { AppSection },
    setup() {
        return {};
    }
});
