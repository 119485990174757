
import { computed, defineComponent, ref } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import { useStore } from "vuex";
import AppUpload from "@/components/common/AppUpload.vue";
import AppButton from "@/components/common/AppButton.vue";
import SettingModel, { CustomFont } from "@/models/SettingModel";
import AppInput from "@/components/common/AppInput.vue";
import ColorPicker from "primevue/colorpicker";
import axios from "axios";
import { useToast } from "primevue/usetoast";

export default defineComponent({
    name: "SettingsBrandPage",
    components: { AppInput, AppButton, AppUpload, AppSection, ColorPicker },
    setup() {
        const store = useStore();
        const toast = useToast();

        const setBrandCustomized = () => {
            const brandCustomized: SettingModel = {
                id:
                    store.state.settings.list.find(
                        (setting: SettingModel) =>
                            setting.key === "brand_customized"
                    )?.id || 0,
                key: "brand_customized",
                value: true,
                type: "bool"
            };
            store.dispatch("settings/createOrUpdateSetting", brandCustomized);
        };

        const defaultAudience = store.getters["audiences/getDefault"]();
        const color = ref(`${defaultAudience.color}` ?? "c1c1c1");
        const changeColor = () => {
            axios
                .patch(`/api/categories/${defaultAudience.id}/edit`, {
                    color: color.value
                })
                .finally(() => {
                    store.dispatch("audiences/fetchAudiences");
                    toast.add({
                        severity: "success",
                        summary: "Color updated",
                        detail: `Your main color has been successfully updated.`,
                        life: 3000
                    });
                    setBrandCustomized();
                });
        };

        const uploading = ref(false);

        const logo = computed((): SettingModel | undefined =>
            store.getters["settings/getSettingByKey"]("logo")
        );

        const logoReset = ref(false);
        const showLogo = computed((): boolean => {
            return !!logo.value && !logoReset.value && !uploading.value;
        });

        const uploadLogo = (files: File[]) => {
            if (files.length) {
                uploading.value = true;
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.onload = function(evt) {
                    return evt.target?.result;
                };

                const reader = new FileReader();
                reader.onloadend = () => {
                    if (reader.result !== "") {
                        const uploadFile: SettingModel = {
                            id: logo.value?.id || 0,
                            key: "logo",
                            value: reader.result as string,
                            type: "file"
                        };

                        store
                            .dispatch(
                                "settings/createOrUpdateSetting",
                                uploadFile
                            )
                            .finally(() => {
                                store
                                    .dispatch("settings/fetchList")
                                    .finally(() => {
                                        logoReset.value = false;
                                        uploading.value = false;
                                    });
                                setBrandCustomized();
                            });
                    }
                };
                reader.readAsDataURL(files[0]);
            }
        };

        async function resetLogo() {
            logoReset.value = true;
        }

        const remove = () => {
            logoReset.value = false;
        };

        const customFontSetting = computed((): SettingModel | undefined =>
            store.state.settings.list.find(
                (setting: SettingModel) => setting.key === "custom_font"
            )
        );

        const customFont = computed(
            (): CustomFont | undefined =>
                customFontSetting.value?.value as CustomFont
        );

        const customFontName = ref(customFont.value?.name || "");
        const customFontFamily = ref(customFont.value?.fontFamily || "");
        const customFontCssUrl = ref(customFont.value?.url || "");

        const updateCustomFont = () => {
            const customFont: SettingModel = {
                id: customFontSetting.value?.id || 0,
                key: "custom_font",
                value: {
                    name: customFontName.value,
                    fontFamily: customFontFamily.value,
                    url: customFontCssUrl.value
                },
                type: "json"
            };
            store
                .dispatch("settings/createOrUpdateSetting", customFont)
                .finally(() => {
                    store.dispatch("settings/fetchList").finally(() => {
                        toast.add({
                            severity: "success",
                            summary: "Font updated",
                            detail: `Your font has been successfully updated.`,
                            life: 3000
                        });
                    });
                    setBrandCustomized();
                });
        };

        return {
            logo,
            resetLogo,
            showLogo,
            uploadLogo,
            remove,
            logoReset,
            color,
            changeColor,
            customFontName,
            customFontFamily,
            customFontCssUrl,
            updateCustomFont
        };
    }
});
