import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mr-3 align-middle" }
const _hoisted_2 = {
  key: 1,
  class: "text-sm"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex justify-start items-start rounded-md p-4 max-w-lg", [_ctx.style]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.type === 'success')
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 0,
            name: "CheckCircle",
            size: "5",
            class: "text-success"
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'danger')
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 1,
            name: "XCircle",
            size: "5",
            class: "text-danger"
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'warning')
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 2,
            name: "Exclamation",
            size: "5",
            class: "text-warning"
          }))
        : _createCommentVNode("", true),
      (_ctx.type === 'info')
        ? (_openBlock(), _createBlock(_component_app_icon, {
            key: 3,
            name: "InfoCircle",
            size: "5",
            class: "text-blue-500"
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.hasHeader)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["text-sm font-medium align-middle leading-6", [_ctx.hasContent ? 'mb-3' : '']])
          }, [
            _renderSlot(_ctx.$slots, "header")
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.hasContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "content")
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.dismissButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_app_icon, {
            name: "X",
            class: "cursor-pointer",
            size: "4",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('dismiss')))
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}