
import { defineComponent, PropType, computed, ref } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";
import clickOutside from "@/directives/clickOutside";
import { SelectOptionInterface } from "@/types";

enum BorderStyle {
    light = "light",
    dark = "dark"
}

export default defineComponent({
    name: "AppSelect",
    components: {
        AppIcon
    },
    directives: {
        "click-outside": clickOutside
    },
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: [String, Object, Number],
            default: null
        },
        options: {
            type: Array as PropType<SelectOptionInterface[]>,
            required: true
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: "Select"
        },
        borderStyle: {
            type: String as PropType<BorderStyle>,
            default: "light",
            validator: (value: string) => {
                return Object.keys(BorderStyle).indexOf(value) !== -1;
            }
        }
    },
    setup(props, { emit }) {
        const borderStyles = {
            light: "border-light-3",
            dark: "border-dark-2"
        };
        const open = ref(false);

        const selectedValue = computed((): string => {
            const selectedOption = props.options.find(
                option => option.value === props.modelValue
            );
            return selectedOption !== undefined ? selectedOption.label : "";
        });

        const updateValue = (value: string | number | object | null) => {
            emit("update:modelValue", value);
            open.value = false;
        };
        const close = () => {
            open.value = false;
        };

        const border = computed(() => {
            return borderStyles[props.borderStyle];
        });

        return {
            open,
            close,
            border,
            selectedValue,
            updateValue
        };
    }
});
