import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-bold leading-9 mb-3" }
const _hoisted_2 = { class: "text-sm mb-6" }
const _hoisted_3 = { class: "flex flex-row min-w-full justify-between mb-8 border-b" }
const _hoisted_4 = { class: "flex-1" }
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "overflow-auto h-133" }
const _hoisted_7 = { class: "w-full h-full pr-3" }
const _hoisted_8 = {
  key: 0,
  class: "h-100 flex items-center justify-center"
}
const _hoisted_9 = {
  key: 1,
  class: "grid grid-cols-3 gap-4"
}
const _hoisted_10 = { class: "rounded-t-lg overflow-hidden border-t border-l border-r border-transparent relative" }
const _hoisted_11 = { class: "rounded-t-lg px-7 py-3 bg-gray-300 border border-light-3" }
const _hoisted_12 = {
  key: 0,
  class: "min-h-30 max-h-96 w-full overflow-hidden"
}
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "h-full w-full text-center inline-flex justify-center"
}
const _hoisted_15 = {
  key: 1,
  class: "overflow-y-auto max-h-56 overflow-x-hidden"
}
const _hoisted_16 = { id: "templateListPreviewWrapper" }
const _hoisted_17 = ["srcdoc"]
const _hoisted_18 = {
  key: 1,
  class: "h-full w-full text-center inline-flex justify-center"
}
const _hoisted_19 = { class: "hidden group-hover:flex flex-col h-full w-full rounded-t-lg overflow-hidden absolute inset-0 bg-dark-1 bg-opacity-70 border border-light-3 justify-center items-center" }
const _hoisted_20 = { class: "flex inline-flex justify-center w-full gap-x-4" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "flex mx-auto justify-center items-center w-10 h-10 rounded-full bg-white content-center mb-1" }
const _hoisted_23 = { class: "block text-center text-white text-sm font-bold" }
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { class: "flex mx-auto justify-center items-center w-10 h-10 rounded-full bg-white content-center mb-1" }
const _hoisted_26 = { class: "block text-center text-white text-sm font-bold" }
const _hoisted_27 = { class: "flex inline-flex absolute top-2 right-2" }
const _hoisted_28 = { class: "bg-light-3 text-center rounded-b-lg border-b border-l border-r border-white" }
const _hoisted_29 = { class: "flex justify-center items-center" }
const _hoisted_30 = { class: "inline-block text-dark-2 font-medium text-sm leading-9 pl-1" }
const _hoisted_31 = { class: "text-center text-sm pt-4 pb-5 px-5 overflow-ellipsis whitespace-nowrap overflow-hidden border-black" }
const _hoisted_32 = {
  key: 2,
  class: "h-100 flex items-center justify-center"
}
const _hoisted_33 = { class: "text-lg font-medium mb-6" }
const _hoisted_34 = { class: "flex" }
const _hoisted_35 = {
  key: 0,
  class: "w-3/5 h-176 overflow-scroll bg-gray-300 text-center"
}
const _hoisted_36 = ["src"]
const _hoisted_37 = {
  key: 1,
  class: "h-full w-full text-center inline-flex justify-center"
}
const _hoisted_38 = {
  key: 1,
  class: "w-3/5 h-176 overflow-y-auto overflow-x-hidden"
}
const _hoisted_39 = {
  id: "templatePreviewWrapper",
  class: "h-full w-full"
}
const _hoisted_40 = ["srcdoc"]
const _hoisted_41 = {
  key: 1,
  class: "h-full w-full text-center inline-flex justify-center"
}
const _hoisted_42 = { class: "flex flex-1 flex-col flex-wrap content-between pl-5" }
const _hoisted_43 = { class: "flex pb-2" }
const _hoisted_44 = { class: "flex flex-grow" }
const _hoisted_45 = {
  key: 0,
  class: "flex w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_app_section = _resolveComponent("app-section")!
  const _component_bee_editor = _resolveComponent("bee-editor")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_app_section, { padding: "8" }, {
      default: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t("pages.settings.library.header")), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("pages.settings.library.headerText")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass(["leading-8 py-3 font-medium inline-block px-2 mr-8 border-b-2 cursor-pointer", [
                        _ctx.activeLibraryFilter === 'all'
                            ? 'text-blue-500 border-blue-500'
                            : 'text-dark-2 border-white hover:text-blue-500 hover:border-blue-500'
                    ]]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.applyTemplateFilter('all')))
            }, _toDisplayString(_ctx.$t("pages.settings.library.allItems")), 3),
            _createElementVNode("span", {
              class: _normalizeClass(["leading-8 py-3 font-medium inline-block px-2 mr-8 border-b-2 cursor-pointer", [
                        _ctx.activeLibraryFilter === 'mails'
                            ? 'text-blue-500 border-blue-500'
                            : 'text-dark-2 border-white hover:text-blue-500 hover:border-blue-500'
                    ]]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.applyTemplateFilter('mails')))
            }, _toDisplayString(_ctx.$t("pages.settings.library.mailsFilter")), 3),
            _createElementVNode("span", {
              class: _normalizeClass(["leading-8 py-3 font-medium inline-block px-2 mr-8 border-b-2 cursor-pointer", [
                        _ctx.activeLibraryFilter === 'rows'
                            ? 'text-blue-500 border-blue-500'
                            : 'text-dark-2 border-white hover:text-blue-500 hover:border-blue-500'
                    ]]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.applyTemplateFilter('rows')))
            }, _toDisplayString(_ctx.$t("pages.settings.library.rowsFilter")), 3)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_button, {
              "button-style": "secondary",
              class: "font-medium my-3",
              onClick: _ctx.addTemplate
            }, {
              left: _withCtx(() => [
                _createVNode(_component_app_icon, { name: "Plus" })
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t("pages.settings.library.addTemplate")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_app_loader)
                ]))
              : (_ctx.libraryItems.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.libraryItems, (template) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "group w-72",
                        key: `${template.type}-${template.data.id}`
                      }, [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            (template.type === 'mail')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                  (template.data.preview)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        src: 
                                            'data:image/jpeg;base64,' +
                                                template.data.preview
                                        ,
                                        alt: ""
                                      }, null, 8, _hoisted_13))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        _createVNode(_component_app_icon, {
                                          name: "Photograph",
                                          size: "20",
                                          class: "text-light-3"
                                        })
                                      ]))
                                ]))
                              : (template.type === 'row')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, [
                                      (template.data.rowHtml.length)
                                        ? (_openBlock(), _createElementBlock("iframe", {
                                            key: 0,
                                            id: "templateListPreview",
                                            class: "frame",
                                            srcdoc: template.data.rowHtml,
                                            sandbox: "allow-same-origin"
                                          }, null, 8, _hoisted_17))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                            _createVNode(_component_app_icon, {
                                              name: "Photograph",
                                              size: "20",
                                              class: "text-light-3"
                                            })
                                          ]))
                                    ])
                                  ]))
                                : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              (
                                            template.type === 'mail' ||
                                                (template.type === 'row' &&
                                                    template.data.pageContent
                                                        .length)
                                        )
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: "text-center cursor-pointer",
                                    onClick: ($event: any) => (
                                            _ctx.editTemplate(
                                                template.data.id,
                                                template.type
                                            )
                                        )
                                  }, [
                                    _createElementVNode("div", _hoisted_22, [
                                      _createVNode(_component_app_icon, {
                                        name: "PencilAlt",
                                        size: "6",
                                        class: "text-dark-1 z-5"
                                      })
                                    ]),
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t("general.edit")), 1)
                                  ], 8, _hoisted_21))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", {
                                class: "text-center cursor-pointer",
                                onClick: ($event: any) => (
                                            _ctx.previewTemplate(
                                                template.data.id,
                                                template.type
                                            )
                                        )
                              }, [
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_app_icon, {
                                    name: "SearchCircle",
                                    size: "6",
                                    class: "text-dark-1 z-5"
                                  })
                                ]),
                                _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t("general.preview")), 1)
                              ], 8, _hoisted_24)
                            ]),
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_app_icon, {
                                name: "Trash",
                                class: "text-white cursor-pointer",
                                size: "4",
                                onClick: ($event: any) => (
                                            _ctx.deleteTemplate(
                                                template.data.id,
                                                template.type
                                            )
                                        )
                              }, null, 8, ["onClick"])
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("span", _hoisted_29, [
                            _createVNode(_component_app_icon, {
                              name: 
                                        template.type === 'mail'
                                            ? 'Mail'
                                            : 'Rows'
                                    
                            }, null, 8, ["name"]),
                            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t(
                                            template.type === "mail"
                                                ? "pages.settings.library.mailType"
                                                : "pages.settings.library.rowType"
                                        )), 1)
                          ])
                        ]),
                        _createElementVNode("p", _hoisted_31, _toDisplayString(template.type === "mail"
                                    ? template.data.title
                                    : template.data.name), 1)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(_ctx.$t(
                            _ctx.activeLibraryFilter === "all"
                                ? "pages.settings.library.emptyLibrary"
                                : _ctx.activeLibraryFilter === "mails"
                                ? "pages.settings.library.noTemplates"
                                : "pages.settings.library.noRows"
                        )), 1))
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_bee_editor, {
      "template-content": _ctx.templateContent,
      "template-name": _ctx.templateName,
      "template-key": _ctx.templateKey,
      "template-type": _ctx.templateType,
      "send-test-mail-enabled": false,
      "save-as-template-enabled": false,
      "switch-template-enabled": false,
      "single-row-mode": _ctx.singleRowMode,
      onSave: _ctx.saveTemplateContent,
      onExit: _ctx.exitEditor
    }, null, 8, ["template-content", "template-name", "template-key", "template-type", "single-row-mode", "onSave", "onExit"]),
    (_ctx.templatePreviewOpen)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 0,
          size: "w-238",
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.templatePreviewOpen = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("general.preview")), 1),
            _createElementVNode("div", _hoisted_34, [
              (_ctx.activeTemplatePreview.type === 'mail')
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    (_ctx.activeTemplatePreview.data.preview)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: 
                        'data:image/jpeg;base64,' +
                            _ctx.activeTemplatePreview.data.preview
                    ,
                          alt: ""
                        }, null, 8, _hoisted_36))
                      : (_openBlock(), _createElementBlock("div", _hoisted_37, [
                          _createVNode(_component_app_icon, {
                            name: "Photograph",
                            size: "20",
                            class: "text-light-3"
                          })
                        ]))
                  ]))
                : (_ctx.activeTemplatePreview.type === 'row')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        (_ctx.activeTemplatePreview.data.rowHtml.length)
                          ? (_openBlock(), _createElementBlock("iframe", {
                              key: 0,
                              id: "templatePreview",
                              class: "h-full w-full",
                              srcdoc: _ctx.activeTemplatePreview.data.rowHtml,
                              sandbox: "allow-same-origin"
                            }, null, 8, _hoisted_40))
                          : (_openBlock(), _createElementBlock("div", _hoisted_41, [
                              _createVNode(_component_app_icon, {
                                name: "Photograph",
                                size: "20",
                                class: "text-light-3"
                              })
                            ]))
                      ])
                    ]))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.activeTemplatePreview.type === "mail"
                                ? _ctx.activeTemplatePreview.data.title
                                : _ctx.activeTemplatePreview.data.name), 1)
                ]),
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.activeTemplatePreview.data.description), 1),
                (_ctx.activeTemplatePreview.type === 'mail')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                      _createVNode(_component_app_button, {
                        size: "regular",
                        class: "w-full",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.createMailFromTemplate(_ctx.activeTemplatePreview)))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("pages.settings.library.createMailFromTemplate")), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}