import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 left-0 right-0 bottom-0 z-20 flex justify-center items-center bg-dark-1 bg-opacity-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["relative bg-white p-5 rounded-md", `${_ctx.size}`])
      }, [
        _createVNode(_component_app_icon, {
          name: "X",
          class: "absolute top-4 right-4 text-dark-3 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ])
  ]))
}