
import { computed, defineComponent, PropType } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

enum TypeEnum {
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info"
}
//todo type styles
export default defineComponent({
    name: "AppAlert",
    components: {
        AppIcon
    },
    props: {
        type: {
            type: String as PropType<TypeEnum>,
            default: "info",
            validator: (value: TypeEnum) => {
                return (
                    ["success", "danger", "warning", "info"].indexOf(value) !==
                    -1
                );
            }
        },
        dismissButton: {
            type: Boolean,
            default: false
        }
    },
    emits: ["dismiss"],
    setup(props, { slots }) {
        const styles = {
            success: "bg-green-50 text-green-800",
            danger: "bg-red-50 text-red-800",
            warning: "bg-yellow-50 text-yellow-800",
            info: "bg-blue-50 text-blue-800"
        };
        const style = computed((): string => {
            return styles[props.type];
        });

        const hasHeader = computed(
            () => slots.header !== undefined && slots.header().length
        );
        const hasContent = computed(
            () => slots.content !== undefined && slots.content().length
        );

        return {
            hasHeader,
            hasContent,
            style
        };
    }
});
