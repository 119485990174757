import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-between mb-2"
}
const _hoisted_2 = {
  key: 0,
  class: "text-sm font-bold text-dark-2"
}
const _hoisted_3 = {
  key: 1,
  class: "text-sm text-dark-2"
}
const _hoisted_4 = ["type", "value", "placeholder", "maxlength"]
const _hoisted_5 = { class: "absolute bottom-1.5 right-2 flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      class: _normalizeClass(["relative flex", [!!_ctx.prefix ? 'flex-row' : 'flex-col']])
    }, [
      (!!_ctx.label || !!_ctx.counter)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (!!_ctx.label)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true),
            (!!_ctx.counter)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.charactersLeft) + " " + _toDisplayString(_ctx.$t("components.appInput.characters")), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.prefix)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(["flex text-sm leading-9 border text-dark-2 bg-light-1 border border-r-0 border-gray-300 rounded-l-md px-3", [_ctx.prefixBorder]])
          }, _toDisplayString(_ctx.prefix), 3))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        type: _ctx.type,
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        maxlength: _ctx.limit || 524288,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputHandler($event, $event.target.value))),
        class: _normalizeClass(["text-sm leading-9 border pl-3", [
                    _ctx.statusText,
                    _ctx.statusBorder,
                    _ctx.status.length ? 'pr-8' : 'pr-3',
                    _ctx.prefix.length ? 'rounded-r-md' : 'rounded-md'
                ]])
      }, null, 42, _hoisted_4),
      _createElementVNode("span", _hoisted_5, [
        (_ctx.status === 'error')
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 0,
              name: "ExclamationCircle",
              size: "5",
              class: "text-red-600"
            }))
          : _createCommentVNode("", true),
        (_ctx.status === 'warning')
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 1,
              name: "InfoCircle",
              size: "5",
              class: "text-orange-500"
            }))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass(["flex items-center justify-start text-sm mt-2", [_ctx.statusDescription]])
        }, _toDisplayString(_ctx.description), 3))
      : _createCommentVNode("", true)
  ]))
}