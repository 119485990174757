import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "bg-white flex justify-between items-center fixed left-0 right-0 top-0 h-17 shadow z-30" }
const _hoisted_2 = { class: "flex justify-start items-center" }
const _hoisted_3 = { class: "border-r border-light-3 px-5 text-dark-2 leading-7 py-5" }
const _hoisted_4 = { class: "hover:underline cursor-pointer" }
const _hoisted_5 = {
  key: 1,
  class: "inline-flex"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "flex border-r border-light-3 text-dark-2 leading-7 py-5 pr-5"
}
const _hoisted_8 = { class: "flex border-r border-light-3 text-dark-2 leading-7 py-5 pr-5" }
const _hoisted_9 = { class: "flex text-dark-2 leading-7 py-5 pr-5" }
const _hoisted_10 = { class: "px-5" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col justify-center items-center fixed inset-0 bg-light-1 z-30"
}
const _hoisted_12 = { class: "text-dark-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_loader = _resolveComponent("app-loader")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#editor-container" }, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.editNameEnabled)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (_ctx.activeItem !== 'templateName')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "inline-flex",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeItem = 'templateName'))
                            }, [
                              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.templateNameModel), 1),
                              _createVNode(_component_app_icon, {
                                name: "PencilAlt",
                                class: "text-dark-2 ml-2 cursor-pointer"
                              })
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createVNode(_component_app_input, {
                                modelValue: _ctx.templateNameModel,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.templateNameModel) = $event)),
                                onKeyup: [
                                  _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.activeItem = ''), ["enter"])),
                                  _withKeys(_ctx.resetTemplateName, ["esc"])
                                ],
                                class: "w-80"
                              }, null, 8, ["modelValue", "onKeyup"]),
                              _createVNode(_component_app_icon, {
                                name: "CheckCircle",
                                class: "ml-5 cursor-pointer",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeItem = ''))
                              }),
                              _createVNode(_component_app_icon, {
                                name: "XCircle",
                                class: "ml-2 cursor-pointer",
                                onClick: _ctx.resetTemplateName
                              }, null, 8, ["onClick"])
                            ]))
                      ], 64))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.templateNameModel), 1))
                ]),
                (
                                _ctx.switchTemplateEnabled || _ctx.saveAsTemplateEnabled
                            )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      (_ctx.switchTemplateEnabled)
                        ? _withDirectives((_openBlock(), _createBlock(_component_app_icon, {
                            key: 0,
                            name: "Template",
                            class: "ml-5 cursor-pointer",
                            onClick: _ctx.switchTemplate
                          }, null, 8, ["onClick"])), [
                            [
                              _directive_tooltip,
                              
                                    _ctx.$t(
                                        'components.mails.editContent.changeTemplate'
                                    )
                                ,
                              void 0,
                              { bottom: true }
                            ]
                          ])
                        : _createCommentVNode("", true),
                      (_ctx.saveAsTemplateEnabled)
                        ? _withDirectives((_openBlock(), _createBlock(_component_app_icon, {
                            key: 1,
                            name: "Save",
                            class: "ml-5 cursor-pointer",
                            onClick: _ctx.saveAsTemplate
                          }, null, 8, ["onClick"])), [
                            [
                              _directive_tooltip,
                              'Save template',
                              void 0,
                              { bottom: true }
                            ]
                          ])
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _withDirectives(_createVNode(_component_app_icon, {
                    name: "SearchCircle",
                    class: "ml-5 cursor-pointer",
                    onClick: _ctx.togglePreview
                  }, null, 8, ["onClick"]), [
                    [
                      _directive_tooltip,
                      
                                    _ctx.$t(
                                        'components.mails.editContent.togglePreview'
                                    )
                                ,
                      void 0,
                      { bottom: true }
                    ]
                  ]),
                  (_ctx.sendTestMailEnabled && _ctx.emailId)
                    ? _withDirectives((_openBlock(), _createBlock(_component_app_icon, {
                        key: 0,
                        name: "Mail",
                        class: "ml-5 cursor-pointer",
                        onClick: _ctx.sendTestMailAction
                      }, null, 8, ["onClick"])), [
                        [
                          _directive_tooltip,
                          
                                    _ctx.$t(
                                        'components.mails.editContent.sendTestMail'
                                    )
                                ,
                          void 0,
                          { bottom: true }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  _withDirectives(_createVNode(_component_app_icon, {
                    name: "Structure",
                    class: "ml-5 cursor-pointer",
                    onClick: _ctx.toggleStructure
                  }, null, 8, ["onClick"]), [
                    [
                      _directive_tooltip,
                      
                                    _ctx.$t(
                                        'components.mails.editContent.toggleStructure'
                                    )
                                ,
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _withDirectives(_createVNode(_component_app_icon, {
                    name: "Shopping",
                    class: "ml-5 cursor-pointer",
                    onClick: _ctx.toggleProductModal
                  }, null, 8, ["onClick"]), [
                    [
                      _directive_tooltip,
                      
                                    _ctx.$t(
                                        'components.mails.editContent.toggleProductModal'
                                    )
                                ,
                      void 0,
                      { bottom: true }
                    ]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_app_button, {
                  "button-style": "transparentUnderline",
                  onClick: _ctx.exitFromEditor
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.exit")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_app_button, { onClick: _ctx.save }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.save")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            (_openBlock(), _createElementBlock("div", {
              id: "bee-editor-container",
              key: _ctx.editorKey,
              class: "fixed top-17 bottom-0 left-0 right-0 z-20"
            }))
          ]),
          (!_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_app_loader),
                _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t("components.beeEditor.editorLoadingText")), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}