
import { computed, defineComponent, toRef } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "AppInput",
    components: {
        AppIcon
    },
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        limit: {
            type: Number,
            default: 0
        },
        counter: {
            type: Number,
            default: 0
        },
        placeholder: {
            type: String,
            default: ""
        },
        status: {
            type: String,
            default: ""
        },
        description: {
            type: String,
            default: ""
        },
        prefix: {
            type: String,
            default: ""
        }
    },
    setup(props, { emit }) {
        const counter = toRef(props, "counter");
        const modelValue = toRef(props, "modelValue");
        const status = toRef(props, "status");

        return {
            statusBorder: computed(() => {
                if (status.value === "error") {
                    return "border-red-400 focus:outline-red";
                }
                if (status.value === "warning") {
                    return "border-orange-400 focus:outline-orange";
                }
                return "border-light-3 focus:outline-none focus:border-blue-500 hover:border-dark-3";
            }),
            statusText: computed(() => {
                if (status.value === "error") {
                    return "text-red-900";
                }
                if (status.value === "warning") {
                    return "text-green-900";
                }
                return "text-dark-2";
            }),
            statusDescription: computed(() => {
                if (status.value === "error") {
                    return "text-red-600";
                }
                if (status.value === "warning") {
                    return "text-orange-500";
                }
                return "text-dark-3";
            }),
            charactersLeft: computed(() =>
                counter.value && counter.value > modelValue.value.length
                    ? counter.value - modelValue.value.length
                    : 0
            ),
            prefixBorder: computed(() => {
                if (status.value === "error") {
                    return "border-red-400";
                }
                if (status.value === "warning") {
                    return "border-orange-400";
                }
                return "border-light-3";
            }),
            inputHandler(event: InputEvent, value: string) {
                emit("update:modelValue", value);
            }
        };
    }
});
