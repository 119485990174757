import { openDialog } from "vue3-promise-dialog";
import SendTestMailModal from "@/components/editor/SendTestMailModal.vue";
import axios from "axios";
import { useToast } from "primevue/usetoast";
import { useStore } from "vuex";
const useSendTestMail = () => {
    const store = useStore();
    const toast = useToast();
    const sendTestMail = async (emailId: string | number) => {
        const sendTestMailRecipients = (await openDialog(
            SendTestMailModal
        )) as string;
        if (sendTestMailRecipients !== null) {
            try {
                await axios.post(`api/emails/${emailId}/send-example`, {
                    emails: sendTestMailRecipients.split(/[ , ;]+/)
                });
                toast.add({
                    severity: "success",
                    summary: "Email sent",
                    detail: "Test email successfully sent.",
                    life: 3000
                });
                window.analytics.track("Test Mail sent", {
                    userId: store.getters["user/emailHash"],
                    mailId: store.state.mails.active.id
                });
            } catch (e) {
                // TODO: Use error from axios reponse
                toast.add({
                    severity: "error",
                    summary: "Send email failed",
                    detail: `Test email couldn't be sent.`,
                    life: 3000
                });
            }
        }
    };

    return { sendTestMail };
};

export default useSendTestMail;
