import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start items-center" }
const _hoisted_2 = { class: "text-lg font-medium mt-4" }
const _hoisted_3 = { class: "text-sm mt-4" }
const _hoisted_4 = { class: "grid grid-cols-2 gap-3 w-full mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    size: "w-128",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog(null)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["inline-flex p-2 rounded-full", [
                    _ctx.type === 'success'
                        ? 'bg-green-50'
                        : _ctx.type === 'danger'
                        ? 'bg-red-50'
                        : _ctx.type === 'warning'
                        ? 'bg-yellow-50'
                        : 'bg-blue-50'
                ]])
        }, [
          (_ctx.type === 'success')
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 0,
                name: "CheckCircle",
                size: "8",
                class: "text-green-400"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'danger')
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 1,
                name: "XCircle",
                size: "8",
                class: "text-danger"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'warning')
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 2,
                name: "Exclamation",
                size: "8",
                class: "text-warning"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'info')
            ? (_openBlock(), _createBlock(_component_app_icon, {
                key: 3,
                name: "InfoCircle",
                size: "8",
                class: "text-blue-500"
              }))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.message), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            "button-style": "secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeDialog('dismiss'))),
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.dismissButtonText)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_app_button, {
            class: "w-full",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog(true)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(_ctx.confirmButtonText)), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}