
import AppModal from "@/components/common/AppModal.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppSelect from "@/components/common/AppSelect.vue";
import AppSearch from "@/components/common/AppSearch.vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { closeDialog } from "vue3-promise-dialog";
import axios from "axios";
import { SelectOptionInterface } from "@/types";
import ProductCategoryModel from "@/models/ProductCategoryModel";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ProductModel from "@/models/ProductModel";
import Checkbox from "primevue/checkbox";

export default defineComponent({
    name: "AddProductsModal",
    components: {
        AppModal,
        AppButton,
        AppSelect,
        AppSearch,
        Checkbox
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();

        let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 1000);

        const currency = computed(() => store.state.store.currency);

        const dynamicProductsSearch = ref("");
        const dynamicProductsCategory = ref(0);
        const dynamicProductCategories = ref([]);
        const dynamicProductsList = ref([] as ProductModel[]);
        const dynamicProductsTotal = ref(0);
        const selectedProducts = ref([] as number[]);
        const productLimit = 10;
        const productStart = ref(0);
        const loadingProducts = ref(false);
        const selectAllProducts = ref(false);
        const resetSelectedProducts = () => {
            selectAllProducts.value = false;
            selectedProducts.value = [];
        };

        const getDynamicProductsList = () => {
            loadingProducts.value = true;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                // TODO add limit and start/implement pagination
                let params = {
                    query: dynamicProductsSearch.value,
                    limit: productLimit,
                    start: productStart.value,
                    category_id: 0
                };
                if (dynamicProductsCategory.value) {
                    params = {
                        ...params,
                        category_id: dynamicProductsCategory.value
                    };
                }

                axios
                    .get(`api/products?visible=true`, { params })
                    .then(response => {
                        if (productStart.value) {
                            dynamicProductsList.value = [
                                ...dynamicProductsList.value,
                                ...(Object.values(
                                    response.data.products
                                ) as ProductModel[])
                            ];
                        } else {
                            dynamicProductsList.value = Object.values(
                                response.data.products
                            );
                        }
                        dynamicProductsList.value = dynamicProductsList.value.map(
                            product => ({
                                ...product,
                                price: Number(product.price).toFixed(2)
                            })
                        );
                        dynamicProductsTotal.value = response.data.total;
                        loadingProducts.value = false;
                    });
            }, 500);
        };
        const categoryOptions = computed((): SelectOptionInterface[] => {
            return [
                {
                    label: t("general.all"),
                    value: 0
                },
                ...dynamicProductCategories.value.map(
                    (category: ProductCategoryModel) => ({
                        label: category.name,
                        value: category.id
                    })
                )
            ];
        });

        watch(dynamicProductsSearch, () => {
            selectAllProducts.value = false;
            productStart.value = 0;
            dynamicProductsList.value = [];
            getDynamicProductsList();
        });
        watch(dynamicProductsCategory, () => {
            selectAllProducts.value = false;
            productStart.value = 0;
            dynamicProductsList.value = [];
            getDynamicProductsList();
        });
        watch(selectAllProducts, val => {
            if (val) {
                selectedProducts.value = dynamicProductsList.value.map(
                    product => product.id
                );
            } else {
                selectedProducts.value = [];
            }
        });

        const handleScroll = (event: Event) => {
            const target = event.target as HTMLElement;
            if (
                !loadingProducts.value &&
                dynamicProductsTotal.value > dynamicProductsList.value.length &&
                target.scrollTop + 420 > target.scrollHeight
            ) {
                productStart.value += productLimit;
                getDynamicProductsList();
            }
        };

        const productFallbackImage = async (e: Event) => {
            if (e.target as HTMLImageElement) {
                const target = e.target as HTMLImageElement;
                target.src = (
                    await import("@/assets/img/placeholder.svg")
                ).default;
            }
        };

        onMounted(() => {
            axios.get("api/product-categories").then(response => {
                dynamicProductCategories.value = Object.values(
                    response.data.categories
                );
            });
        });

        return {
            dynamicProductsSearch,
            dynamicProductsCategory,
            dynamicProductsList,
            selectedProducts,
            loadingProducts,
            getDynamicProductsList,
            categoryOptions,
            selectAllProducts,
            handleScroll,
            productFallbackImage,
            currency,
            resetSelectedProducts,
            closeDialog
        };
    }
});
