
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import AppIcon from "@/components/common/AppIcon.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppLoader from "@/components/common/AppLoader.vue";
import AppModal from "@/components/common/AppModal.vue";
import AppSection from "@/components/common/AppSection.vue";
import TemplateModel from "@/models/TemplateModel";
import { openDialog } from "vue3-promise-dialog";
import AppConfirmation from "@/components/common/AppConfirmation.vue";
import { useI18n } from "vue-i18n";
import BeeEditor from "@/components/editor/BeeEditor.vue";
import ChooseTemplateModal from "@/components/editor/ChooseTemplateModal.vue";
import moment from "moment";
import RowModel from "@/models/RowModel";
import { useToast } from "primevue/usetoast";
import MailModel from "@/models/MailModel";
import { useRouter } from "vue-router";
import useUnicodeHelper from "@/composables/useUnicodeHelper";

export interface LibraryItem {
    type: string;
    sortDate: string;
    data: TemplateModel | RowModel;
}
export default defineComponent({
    name: "SettingsTemplatesPage",
    components: {
        BeeEditor,
        AppButton,
        AppIcon,
        AppLoader,
        AppModal,
        AppSection
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();
        const toast = useToast();
        const router = useRouter();
        const { encodeUnicode } = useUnicodeHelper();

        const loading = ref(true);
        const activeLibraryFilter = ref("all");

        const applyTemplateFilter = (filter: string) => {
            activeLibraryFilter.value = filter;
        };

        const templates = computed(() => {
            if (activeLibraryFilter.value === "rows")
                // TODO: Filter by rows
                return [];
            else {
                return store.getters["templates/getUserTemplates"];
            }
        });

        onMounted(() => {
            store.dispatch("rows/fetchList");
            if (store.getters["templates/getList"].length > 0) {
                loading.value = false;
                store.dispatch("templates/fetchList").finally();
                return;
            } else {
                store.dispatch("templates/fetchList").finally(() => {
                    loading.value = false;
                });
            }
        });

        const libraryItems = computed(() => {
            const mailTemplates = store.getters[
                "templates/getUserTemplates"
            ].map((template: TemplateModel) => {
                return {
                    type: "mail",
                    sortDate: template.dateModified ?? template.dateAdded,
                    data: template
                };
            }) as LibraryItem[];
            const rows = store.getters["rows/getList"].map((row: RowModel) => {
                return {
                    type: "row",
                    sortDate: row.dateModified ?? row.dateAdded,
                    data: row
                };
            }) as LibraryItem[];
            if (activeLibraryFilter.value === "mails") {
                return [...mailTemplates].sort(
                    (a, b) =>
                        moment(b.sortDate).valueOf() -
                        moment(a.sortDate).valueOf()
                );
            } else if (activeLibraryFilter.value === "rows") {
                return [...rows].sort(
                    (a, b) =>
                        moment(b.sortDate).valueOf() -
                        moment(a.sortDate).valueOf()
                );
            }
            return [...mailTemplates, ...rows].sort(
                (a, b) =>
                    moment(b.sortDate).valueOf() - moment(a.sortDate).valueOf()
            );
        });

        const templatePreviewOpen = ref(false);
        const activeTemplatePreview = ref({} as LibraryItem);
        async function previewTemplate(id: number, type: string) {
            activeTemplatePreview.value = libraryItems.value.filter(
                (item: LibraryItem) => item.type === type && item.data.id === id
            )[0];
            templatePreviewOpen.value = true;
        }

        async function deleteTemplate(id: number, type: string) {
            if (
                (await openDialog(AppConfirmation, {
                    title: t("pages.settings.library.deleteModalHeader"),
                    message: t("pages.settings.library.deleteModalMessage"),
                    confirmButtonText: "general.delete",
                    type: "warning"
                })) === true
            ) {
                if (type === "mail") {
                    await store.dispatch("templates/deleteById", id);
                    await store.dispatch("templates/fetchList").finally(() => {
                        toast.add({
                            severity: "success",
                            summary: "Mail template deleted",
                            detail: `Mail template has been successfully removed.`,
                            life: 3000
                        });
                    });
                } else if (type === "row") {
                    await store.dispatch("rows/deleteById", id);
                    await store.dispatch("rows/fetchList").finally(() => {
                        toast.add({
                            severity: "success",
                            summary: "Row deleted",
                            detail: `Row has been successfully removed.`,
                            life: 3000
                        });
                    });
                }
            }
        }

        const singleRowMode = ref(false);
        const activeTemplate = ref<TemplateModel | RowModel>(
            {} as TemplateModel | RowModel
        );
        const templateType = ref("mail");
        const templateContent = ref("");
        const templateName = ref("");
        const templateKey = ref("");

        const editTemplate = (id: number, type: string) => {
            if (type === "mail") {
                singleRowMode.value = false;
                const template = templates.value.filter(
                    (template: TemplateModel) => template.id === id
                )[0];
                store.commit("templates/SET_ACTIVE", template);
                activeTemplate.value = template;
                templateContent.value = template.content;
                templateName.value = template.title;
                templateKey.value = `mail_${template.id}`;
                templateType.value = "mail";
            }

            if (type === "row") {
                singleRowMode.value = true;
                const row = store.getters["rows/getList"].filter(
                    (row: RowModel) => row.id === id
                )[0];
                store.commit("rows/SET_ACTIVE", row);
                activeTemplate.value = row;
                templateContent.value = row.pageContent;
                templateName.value = row.name;
                templateKey.value = `row_${row.id}`;
                templateType.value = "row";
            }
        };

        const addTemplate = async () => {
            const chosenTemplate: TemplateModel = await openDialog(
                ChooseTemplateModal
            );
            if (chosenTemplate !== null) {
                const newTemplateName = t(
                    "pages.settings.library.newTemplateTitle",
                    { date: moment().format("YYYY-MM-DD HH:mm") }
                );
                activeTemplate.value = {
                    id: 0,
                    title: newTemplateName,
                    content: chosenTemplate.content,
                    preview: "",
                    author: store.state.user.me.username,
                    description: "",
                    isPublished: true,
                    dateAdded: moment().format("YYYY-MM-DD HH:mm")
                } as TemplateModel;
                store.commit("templates/SET_ACTIVE", activeTemplate.value);

                templateContent.value = chosenTemplate.content;
                templateName.value = newTemplateName;
                templateKey.value = "template_new";
            }
        };

        const saveTemplateContent = (payload: {
            templateContent: string;
            htmlContent: string;
            templateName: string;
            templateType: string;
        }) => {
            if (payload.templateType === "mail") {
                const encodedTemplateContent = encodeUnicode(
                    payload.templateContent
                );

                (activeTemplate.value as TemplateModel).title =
                    payload.templateName;
                (activeTemplate.value as TemplateModel).content = encodedTemplateContent;
                delete (activeTemplate.value as TemplateModel).preview;
                store
                    .dispatch("templates/editActive", activeTemplate.value)
                    .finally(() => {
                        store.dispatch("templates/fetchList").finally();
                    });
            } else if (payload.templateType === "row") {
                const template = JSON.parse(payload.templateContent);
                const rowContent = template.page.rows[0];
                rowContent.metadata.name = payload.templateName;
                const encodedTemplateContent = encodeUnicode(
                    payload.templateContent
                );
                const encodedRowContent = encodeUnicode(
                    JSON.stringify(rowContent)
                );
                delete (activeTemplate.value as RowModel).name;
                (activeTemplate.value as RowModel).rowJson = encodedRowContent;
                (activeTemplate.value as RowModel).pageJson = encodedTemplateContent;
                (activeTemplate.value as RowModel).rowHtml =
                    payload.htmlContent;

                store
                    .dispatch("rows/editActive", activeTemplate.value)
                    .finally(() => {
                        store.dispatch("rows/fetchList").finally();
                    });
            }
        };

        const exitEditor = () => {
            templateContent.value = "";
            activeTemplate.value = {} as TemplateModel;
            store.commit("templates/CLEAR_ACTIVE");
        };

        const createMailFromTemplate = (libraryItem: LibraryItem) => {
            const template = libraryItem.data as TemplateModel;
            const templateContent = encodeUnicode(
                JSON.stringify(template.content)
            );
            const newMail = {
                id: 0,
                isPublished: false,
                emailType: "list",
                name: `${t("components.beeEditor.newMailPrefix")} ${
                    template.title
                }`,
                sentCount: 0,
                subject: "Blank",
                preheaderText: "",
                lists: [],
                fromName: null,
                fromAddress: null,
                beeTemplate: templateContent,
                customHtml: "",
                utmTags: {
                    utmCampaign: `${t("components.beeEditor.newMailPrefix")} ${
                        template.title
                    }`,
                    utmContent: null,
                    utmMedium: "email",
                    utmSource: "maatoo"
                }
            } as MailModel;
            store.commit("mails/SET_ACTIVE", newMail);
            router.push({
                name: "mail-edit",
                params: { id: 0 }
            });
        };

        return {
            templates,
            loading,
            applyTemplateFilter,
            activeLibraryFilter,
            deleteTemplate,
            previewTemplate,
            activeTemplatePreview,
            templatePreviewOpen,
            editTemplate,
            templateContent,
            templateName,
            templateKey,
            templateType,
            addTemplate,
            saveTemplateContent,
            exitEditor,
            libraryItems,
            singleRowMode,
            createMailFromTemplate
        };
    }
});
