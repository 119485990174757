import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start" }
const _hoisted_2 = { class: "text-lg font-medium" }
const _hoisted_3 = { class: "text-sm py-2 mb-5" }
const _hoisted_4 = { class: "overflow-auto h-128" }
const _hoisted_5 = { class: "w-full h-full pr-3" }
const _hoisted_6 = {
  key: 0,
  class: "grid grid-cols-3 gap-4"
}
const _hoisted_7 = { class: "flex flex-col justify-start items-center" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "h-96 w-full overflow-hidden" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "h-full w-full text-center inline-flex justify-center"
}
const _hoisted_12 = {
  key: 1,
  class: "h-100 flex items-center justify-center"
}
const _hoisted_13 = {
  key: 2,
  class: "h-100 flex items-center justify-center"
}
const _hoisted_14 = { class: "flex justify-end mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    size: "max-w-screen-lg w-full",
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeDialog(null)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("components.chooseTemplateModal.chooseTemplate")), 1),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("components.chooseTemplateModal.chooseTemplateText")), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (
                            !_ctx.templatesLoading &&
                                (_ctx.showEmptyTemplate || _ctx.templatesList.length)
                        )
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.showEmptyTemplate)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "cursor-pointer group",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTemplate(_ctx.emptyTemplate)))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["rounded-lg overflow-hidden", 
                                        _ctx.selectedTemplate &&
                                        _ctx.selectedTemplate.id === 0
                                            ? 'border-0'
                                            : 'border border-transparent hover:border-0'
                                    ])
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["rounded-lg px-7 py-10", 
                                            _ctx.selectedTemplate &&
                                            _ctx.selectedTemplate.id === 0
                                                ? 'border-2 border-blue-500'
                                                : 'border border-light-3 hover:border-2'
                                        ])
                          }, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["inline-flex p-2 rounded-full", 
                                                    _ctx.selectedTemplate &&
                                                    _ctx.selectedTemplate.id === 0
                                                        ? 'bg-blue-50'
                                                        : 'bg-light-1'
                                                ])
                              }, [
                                _createVNode(_component_app_icon, {
                                  name: "ViewGridAdd",
                                  size: "8",
                                  class: _normalizeClass(
                                                        _ctx.selectedTemplate &&
                                                        _ctx.selectedTemplate.id ===
                                                            0
                                                            ? 'text-blue-500'
                                                            : 'text-gray-400'
                                                    )
                                }, null, 8, ["class"])
                              ], 2),
                              _createElementVNode("span", {
                                class: _normalizeClass(["text-sm pt-4", 
                                                    _ctx.selectedTemplate &&
                                                    _ctx.selectedTemplate.id === 0
                                                        ? 'text-blue-500'
                                                        : ''
                                                ])
                              }, _toDisplayString(_ctx.$t(
                                                        "components.chooseTemplateModal.startFromScratch"
                                                    )), 3)
                            ])
                          ], 2)
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatesList, (templateItem) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "cursor-pointer group",
                      key: templateItem.id,
                      onClick: ($event: any) => (_ctx.selectTemplate(templateItem))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["rounded-lg overflow-hidden", 
                                        _ctx.selectedTemplate &&
                                        _ctx.selectedTemplate.id === templateItem.id
                                            ? 'border-0'
                                            : 'border border-transparent hover:border-0'
                                    ])
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["rounded-lg px-7 py-3 bg-gray-300", 
                                            _ctx.selectedTemplate &&
                                            _ctx.selectedTemplate.id ===
                                                templateItem.id
                                                ? 'border-2 border-blue-500'
                                                : 'border border-light-3 hover:border-2'
                                        ])
                        }, [
                          _createElementVNode("div", _hoisted_9, [
                            (templateItem.preview)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  src: 
                                                    'data:image/jpeg;base64,' +
                                                        templateItem.preview
                                                ,
                                  alt: ""
                                }, null, 8, _hoisted_10))
                              : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                  _createVNode(_component_app_icon, {
                                    name: "Photograph",
                                    size: "20",
                                    class: "text-light-3"
                                  })
                                ]))
                          ])
                        ], 2)
                      ], 2),
                      _createElementVNode("p", {
                        class: _normalizeClass(["text-center text-sm pt-4 pb-5 px-5 overflow-ellipsis whitespace-nowrap overflow-hidden", 
                                        _ctx.selectedTemplate &&
                                        _ctx.selectedTemplate.id === templateItem.id
                                            ? 'text-blue-500'
                                            : ''
                                    ])
                      }, _toDisplayString(templateItem.title), 3)
                    ], 8, _hoisted_8))
                  }), 128))
                ]))
              : (_ctx.templatesLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_component_app_loader)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t("components.chooseTemplateModal.noTemplates")), 1))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_app_button, {
            "button-style": "transparentUnderline",
            class: "mr-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog(null)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_app_button, {
            disabled: !_ctx.templateSelected,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog(_ctx.selectedTemplate)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t(
                            "components.chooseTemplateModal.chooseTemplateAction"
                        )), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }))
}