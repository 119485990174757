
import { defineComponent } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "AppSearch",
    components: {
        AppIcon
    },
    emits: ["update:modelValue"],
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    setup(props, { emit }) {
        return {
            inputHandler(event: InputEvent, value: string) {
                emit("update:modelValue", value);
            },
            clearInput() {
                emit("update:modelValue", "");
            }
        };
    }
});
