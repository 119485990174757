
import { defineComponent } from "vue";
import AppIcon from "@/components/common/AppIcon.vue";

export default defineComponent({
    name: "AppModal",
    components: {
        AppIcon
    },
    props: {
        size: { type: [Number, String], default: "w-128" }
    },
    emits: ["close"]
});
