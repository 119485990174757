import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inline-block text-sm font-bold text-dark-2 mb-2"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  class: "inline-block"
}
const _hoisted_4 = {
  key: 1,
  class: "inline-block text-light-3"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["inline-flex justify-between items-center bg-white leading-9 px-4 cursor-pointer w-full border rounded-md", [_ctx.border]]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
        "data-test": "open"
      }, [
        (_ctx.selectedValue)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selectedValue), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.placeholder), 1)),
        _createVNode(_component_app_icon, {
          name: "Selector",
          size: "5",
          class: "ml-4"
        })
      ], 2),
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["absolute top-10 left-0 right-0 bg-white border rounded-md overflow-y-auto max-h-40", [_ctx.border]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.value,
                onClick: _withModifiers(($event: any) => (_ctx.updateValue(option.value)), ["stop"]),
                class: "leading-8 px-4 hover:bg-blue-500 hover:text-white cursor-pointer",
                "data-test": "select"
              }, _toDisplayString(option.label), 9, _hoisted_5))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ])), [
      [_directive_click_outside, { handler: () => _ctx.close(), exclude: [] }]
    ])
  ]))
}