
import AppInput from "@/components/common/AppInput.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppModal from "@/components/common/AppModal.vue";
import { defineComponent, ref } from "vue";
import { closeDialog } from "vue3-promise-dialog";
import { useStore } from "vuex";

export default defineComponent({
    name: "SendTestMailModal",
    components: { AppButton, AppInput, AppModal },
    setup() {
        const store = useStore();
        const sendTestMailRecipients = ref(store.state.user.me.email || "");

        return {
            sendTestMailRecipients,
            closeDialog
        };
    }
});
