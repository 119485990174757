
import { computed, defineComponent, onMounted, ref } from "vue";
import AppModal from "@/components/common/AppModal.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppLoader from "@/components/common/AppLoader.vue";
import { closeDialog } from "vue3-promise-dialog";
import { useStore } from "vuex";
import TemplateModel from "@/models/TemplateModel";
import AppIcon from "@/components/common/AppIcon.vue";
import { useI18n } from "vue-i18n";
import moment from "moment/moment";

export default defineComponent({
    name: "ChooseTemplateModal",
    components: {
        AppIcon,
        AppModal,
        AppButton,
        AppLoader
    },
    props: {
        showEmptyTemplate: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const store = useStore();
        const { t } = useI18n();

        const emptyTemplate: TemplateModel = {
            id: 0,
            title: t("components.chooseTemplateModal.newTemplateName", {
                date: moment().format("YYYY-MM-DD HH:mm")
            }),
            content: "{}",
            preview: "",
            author: store.state.user.me.username,
            description: "",
            isPublished: true,
            dateAdded: moment().format("YYYY-MM-DD HH:mm")
        };
        const templatesLoading = ref(true);
        const templateSelected = ref(false);
        const selectedTemplate = ref({} as TemplateModel);

        onMounted(() => {
            if (!store.state.templates.list.length) {
                store.dispatch("templates/fetchList").finally(() => {
                    templatesLoading.value = false;
                });
            } else {
                templatesLoading.value = false;
            }
        });

        const selectTemplate = (template: TemplateModel) => {
            console.log("selectTemplate", template);
            if (selectedTemplate.value.id === template.id) {
                selectedTemplate.value = {} as TemplateModel;
                templateSelected.value = false;
            } else {
                selectedTemplate.value = template;
                templateSelected.value = true;
            }
        };

        return {
            templatesList: computed(
                (): TemplateModel[] => store.getters["templates/getList"]
            ),
            templatesLoading,
            selectedTemplate,
            selectTemplate,
            emptyTemplate,
            templateSelected,
            closeDialog
        };
    }
});
