
import { defineComponent, ref, watch } from "vue";
import AppAlert from "@/components/common/AppAlert.vue";
import { useI18n } from "vue-i18n";
import AppButton from "@/components/common/AppButton.vue";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
    name: "AppUpload",
    components: { AppButton, AppAlert, ProgressBar },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        accept: {
            type: String,
            default: "image/*"
        },
        invalidFileSizeMessage: {
            type: String,
            default:
                "{0}: Invalid file size, file size should be smaller than {1}."
        },
        invalidFileTypeMessage: {
            type: String,
            default: "{0}: Invalid file type, allowed file types: {1}."
        },
        fileLimit: {
            type: Number,
            default: 1
        },
        invalidFileLimitMessage: {
            type: String,
            default: "Maximum number of files exceeded, limit is {0} at most."
        },
        maxFileSize: {
            type: Number,
            default: 10000000
        },
        reset: {
            type: Boolean,
            default: false
        }
    },
    emits: ["upload", "remove", "clear"],
    setup(props, { emit }) {
        const { t } = useI18n();

        const reset = ref(props.reset);

        //const fileInput = ref(null as HTMLInputElement | null);
        const dropAreaActive = ref(false);
        const files = ref([] as File[]);
        const messages = ref([] as string[]);
        const uploadedFileCount = ref(0);

        const hasFiles = () => {
            return files.value && files.value.length > 0;
        };

        const onDragEnter = (event: DragEvent) => {
            event.stopPropagation();
            event.preventDefault();
        };

        const onDragOver = (event: DragEvent) => {
            dropAreaActive.value = true;
            event.stopPropagation();
            event.preventDefault();
        };

        const onDragLeave = () => {
            dropAreaActive.value = false;
        };

        const isImage = (file: File) => {
            return /^image\//.test(file.type);
        };

        const getTypeClass = (fileType: string) => {
                return fileType.substring(0, fileType.indexOf("/"));
            },
            isWildcard = (fileType: string | string[]) => {
                return fileType.indexOf("*") !== -1;
            },
            getFileExtension = (file: File) => {
                return "." + file.name.split(".").pop();
            };

        const remove = (index: number) => {
            //this.clearInputElement();
            const removedFile = files.value.splice(index, 1)[0];

            files.value = [...files.value];
            emit("remove", {
                file: removedFile,
                files: files
            });
        };

        const clearInputElement = () => {
            //fileInput.value = "";
        };

        const clear = () => {
            files.value = [];
            messages.value = [];
            emit("clear");

            clearInputElement();
        };

        const isFileLimitExceeded = () => {
            return (
                props.fileLimit &&
                props.fileLimit < files.value.length + uploadedFileCount.value
            );
        };

        const checkFileLimit = () => {
            if (isFileLimitExceeded()) {
                messages.value.push(
                    t("components.appUpload.invalidFileLimitMessage", {
                        fileLimit: props.fileLimit.toString()
                    })
                );
            }
        };

        const isFileTypeValid = (file: File) => {
            const acceptableTypes = props.accept
                .split(",")
                .map(type => type.trim());

            for (const type of acceptableTypes) {
                const acceptable = isWildcard(type)
                    ? getTypeClass(file.type) === getTypeClass(type)
                    : file.type == type ||
                      getFileExtension(file).toLowerCase() ===
                          type.toLowerCase();

                if (acceptable) {
                    return true;
                }
            }

            return false;
        };

        const formatSize = (bytes: number) => {
            if (bytes === 0) {
                return "0 B";
            }

            const k = 1000,
                dm = 3,
                sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
                i = Math.floor(Math.log(bytes) / Math.log(k));

            return (
                parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) +
                " " +
                sizes[i]
            );
        };

        const validate = (file: File) => {
            if (props.accept && !isFileTypeValid(file)) {
                messages.value.push(
                    t("components.appUpload.invalidFileTypeMessage", {
                        fileName: file.name,
                        allowedFileTypes: props.accept
                    })
                );

                return false;
            }

            if (props.maxFileSize && file.size > props.maxFileSize) {
                messages.value.push(
                    t("components.appUpload.invalidFileSizeMessage", {
                        fileName: file.name,
                        maxSize: formatSize(props.maxFileSize)
                    })
                );

                return false;
            }

            return true;
        };

        const isFileSelected = (file: File) => {
            if (files.value && files.value.length) {
                for (const sFile of files.value) {
                    if (
                        sFile.name + sFile.type + sFile.size ===
                        file.name + file.type + file.size
                    )
                        return true;
                }
            }

            return false;
        };

        const onFileSelect = (event: DragEvent | Event) => {
            messages.value = [];
            const fileList = [] as File[];
            if (event instanceof DragEvent && event.dataTransfer) {
                fileList.push(...event.dataTransfer.files);
            } else if (event instanceof Event && event.target) {
                const input = event.target as HTMLInputElement;
                if (input.files) {
                    fileList.push(...input.files);
                }
            }

            for (const file of fileList) {
                if (!isFileSelected(file)) {
                    if (validate(file)) {
                        files.value.push(file);
                    }
                }
            }

            if (props.fileLimit) {
                checkFileLimit();
            }

            if (hasFiles() && !isFileLimitExceeded()) {
                emit("upload", files.value);
            }

            dropAreaActive.value = false;
        };

        const onDrop = (event: DragEvent) => {
            event.stopPropagation();
            event.preventDefault();

            const files = event.dataTransfer?.files;
            const allowDrop = props.multiple || (files && files.length === 1);

            if (allowDrop) {
                onFileSelect(event);
            } else {
                dropAreaActive.value = false;
            }
        };

        const getObjectUrl = (file: File) => {
            return window.URL.createObjectURL(file);
        };

        const onMessageClose = () => {
            messages.value = [];
        };

        watch(
            () => props.reset,
            () => {
                if (props.reset) {
                    clear();
                    reset.value = true;
                } else {
                    reset.value = false;
                }
            }
        );

        return {
            dropAreaActive,
            files,
            messages,
            onDragEnter,
            onDragOver,
            onDrop,
            onDragLeave,
            onFileSelect,
            isImage,
            formatSize,
            remove,
            getObjectUrl,
            onMessageClose
        };
    }
});
