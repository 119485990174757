import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative flex flex-col" }
const _hoisted_2 = { class: "absolute bottom-2 left-2 flex items-center" }
const _hoisted_3 = ["value", "placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "absolute bottom-2 right-2 flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_app_icon, {
          name: "Search",
          size: "5",
          class: "text-dark-3"
        })
      ]),
      _createElementVNode("input", {
        type: "text",
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inputHandler($event, $event.target.value))),
        class: "text-sm leading-9 border rounded-md pl-8 border-light-3 focus:outline-none focus:border-blue-500 hover:border-dark-3"
      }, null, 40, _hoisted_3),
      (_ctx.modelValue.length)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_app_icon, {
              name: "X",
              size: "5",
              class: "cursor-pointer",
              onClick: _ctx.clearInput
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}