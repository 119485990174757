
import { defineComponent } from "vue";
import AppSection from "@/components/common/AppSection.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import { useRoute } from "vue-router";
import store from "@/store";

export default defineComponent({
    name: "SettingsPage",
    components: {
        AppSection,
        AppIcon
    },
    async beforeRouteEnter(to, from, next) {
        // If we already have emails, we reload in the background
        if (store.state.settings.total > 0) {
            store.dispatch("settings/fetchList").finally();
            next();
        } else {
            await store.dispatch("settings/fetchList").finally();
            next();
        }
    },
    setup() {
        const route = useRoute();

        const routes = [
            {
                to: "/settings/brand",
                name: "settings-brand",
                icon: "Adjustments"
            },
            {
                to: "/settings/library",
                name: "settings-library",
                icon: "Template"
            } /**,
            { to: "/settings/legal", name: "settings-legal", icon: "BookOpen" },
            {
                to: "/settings/billing",
                name: "settings-billing",
                icon: "CurrencyDollar"
            }**/
        ];

        const isRouteActive = (name: string): boolean => {
            return route.matched.some(r => r.name === name);
        };

        return {
            routes,
            isRouteActive
        };
    }
});
