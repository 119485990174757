import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "leading-17 mb-8" }
const _hoisted_2 = { class: "bg-white rounded-md shadow-lg" }
const _hoisted_3 = { class: "flex justify-start items-stretch" }
const _hoisted_4 = { class: "w-64 py-6" }
const _hoisted_5 = { class: "inline-block ml-2 font-medium text-sm" }
const _hoisted_6 = { class: "flex-1 border-l border-light-3 py-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_section = _resolveComponent("app-section")!

  return (_openBlock(), _createBlock(_component_app_section, { class: "pt-5 pb-8" }, {
    default: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t("pages.settings.header")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                key: route.name,
                to: route.to
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["flex group justify-start cursor-pointer items-center border-l-4 border-white px-4 py-2 mb-1 text-medium", [
                                _ctx.isRouteActive(route.name)
                                    ? 'text-blue-500 bg-blue-50 border-blue-500'
                                    : 'text-dark-2 hover:text-blue-500 hover:bg-blue-50 hover:border-blue-500'
                            ]])
                  }, [
                    _createVNode(_component_app_icon, {
                      name: route.icon,
                      class: _normalizeClass([
                                    _ctx.isRouteActive(route.name)
                                        ? 'text-blue-600'
                                        : 'text-dark-3 group-hover:text-blue-600'
                                ])
                    }, null, 8, ["name", "class"]),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`routes.${route.name}`)), 1)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_view)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}