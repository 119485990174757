
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    PropType,
    ref,
    watch
} from "vue";
import AppLoader from "@/components/common/AppLoader.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import AppInput from "@/components/common/AppInput.vue";
import axios from "axios";
import TokenModel from "@/models/TokenModel";
import { useStore } from "vuex";
import BeePlugin from "@mailupinc/bee-plugin";

import {
    BeePluginError,
    IBeeConfig
} from "@mailupinc/bee-plugin/dist/types/bee";
import { openDialog } from "vue3-promise-dialog";
import AppConfirmation from "@/components/common/AppConfirmation.vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import SettingModel, { CustomFont } from "@/models/SettingModel";
import SaveRowModal from "@/components/editor/SaveRowModal.vue";
import { v4 as uuidv4 } from "uuid";
import useUnicodeHelper from "@/composables/useUnicodeHelper";
import AddProductsModal from "@/components/editor/AddProductsModal.vue";
import useSendTestMail from "@/composables/useSendTestMail";
import TemplateModel from "@/models/TemplateModel";
import ChooseTemplateModal from "@/components/editor/ChooseTemplateModal.vue";

enum TemplateType {
    mail = "mail",
    row = "row"
}

export default defineComponent({
    name: "BeeEditor",
    components: {
        AppLoader,
        AppButton,
        AppIcon,
        AppInput
    },
    props: {
        templateContent: {
            type: String,
            required: true
        },
        templateName: {
            type: String
        },
        templateType: {
            type: String as PropType<TemplateType>,
            default: "mail",
            validator: (value: TemplateType) => {
                return Object.values(TemplateType).includes(value);
            }
        },
        templateKey: {
            type: String,
            required: true
        },
        switchTemplateEnabled: {
            type: Boolean,
            default: false
        },
        saveAsTemplateEnabled: {
            type: Boolean,
            default: false
        },
        sendTestMailEnabled: {
            type: Boolean,
            default: false
        },
        singleRowMode: {
            type: Boolean,
            default: false
        },
        emailId: {
            type: Number,
            required: false
        },
        editNameEnabled: {
            type: Boolean,
            default: true
        }
    },
    emits: ["save", "exit"],
    setup(props, { emit }) {
        const store = useStore();
        const { t } = useI18n();
        const toast = useToast();
        const { encodeUnicode } = useUnicodeHelper();
        const { sendTestMail } = useSendTestMail();

        const show = ref(false);
        const editorKey = ref(1);
        const loaded = ref(false);
        const hasChanges = ref(false);
        const shouldExit = ref(false);
        const activeItem = ref("");
        const templateNameModel = ref("");

        let token = {} as TokenModel;
        let bee = (null as unknown) as BeePlugin;

        onMounted(() => {
            if (!store.state.audiences) {
                store.dispatch("audiences/fetchAudiences").finally();
            }
            if (!store.state.buildertokens.is_fetched) {
                store.dispatch("buildertokens/fetchState").finally();
            }
        });

        const exit = (removeAutoSave = true) => {
            emit("exit");
            document.body.classList.remove("overflow-hidden");
            if (removeAutoSave) {
                localStorage.removeItem(props.templateKey);
            }
            hasChanges.value = false;
            show.value = false;
        };

        const onSave = (jsonFile: string, htmlFile: string) => {
            emit("save", {
                templateContent: jsonFile,
                htmlContent: htmlFile,
                templateName: templateNameModel.value,
                templateType: props.templateType
            });
            localStorage.removeItem(props.templateKey);
            hasChanges.value = false;

            if (shouldExit.value) {
                shouldExit.value = false;
                exit();
            }
        };

        const onSaveRow = (
            rowJSON: string,
            rowHTML: string,
            pageJSON: string
        ) => {
            const rowData = JSON.parse(rowJSON);
            const rowId = rowData.metadata?.rowId;
            const name = rowData.metadata?.name;

            axios
                .post("api/rows/new", {
                    rowId: rowId,
                    rowJson: encodeUnicode(JSON.stringify(rowData)),
                    pageJson: encodeUnicode(pageJSON),
                    rowHtml: rowHTML
                })
                .then(() => {
                    toast.add({
                        severity: "success",
                        summary: "Row Saved",
                        detail: `Row '${name}' has been successfully saved.`,
                        life: 3000
                    });
                    store.dispatch("rows/fetchList").finally();
                })
                .catch(() => {
                    toast.add({
                        severity: "error",
                        summary: "Saving row failed",
                        detail: `There was an error saving row '${name}'. Please try again.`,
                        life: 3000
                    });
                });
        };

        const onSaveAsTemplate = (jsonFile: string) => {
            const title = t("components.beeEditor.newTemplateTitle", {
                title: templateNameModel.value
            });
            axios
                .post("api/bee/template/new", {
                    template: encodeUnicode(JSON.stringify(jsonFile)),
                    title: title
                })
                .then(() => {
                    store.dispatch("templates/fetchList");
                    toast.add({
                        severity: "success",
                        summary: "Template created",
                        detail: `Template '${title}' has been successfully created.`,
                        life: 3000
                    });
                })
                .catch(() => {
                    toast.add({
                        severity: "error",
                        summary: "Creating template failed",
                        detail: `There was an error saving row '${title}'. Please try again.`,
                        life: 3000
                    });
                });
        };

        const onLoad = () => {
            loaded.value = true;
        };

        const onChange = () => {
            hasChanges.value = true;
        };

        const onError = (errorMessage: BeePluginError) => {
            console.warn(
                "*** [bee] (error) message --> ",
                errorMessage.message
            );
        };
        const onWarning = (errorMessage: BeePluginError) => {
            console.warn("*** [bee] (warn) message --> ", errorMessage.message);
        };

        const onAutoSave = (jsonFile: string) => {
            localStorage.setItem(props.templateKey, jsonFile);
        };

        const save = async () => {
            if (bee) {
                await bee.save();
            }
        };

        const exitFromEditor = async () => {
            if (hasChanges.value) {
                const unsavedChangesPrompt = await openDialog(AppConfirmation, {
                    title: t("components.beeEditor.unsavedModalHeader"),
                    message: t("components.beeEditor.unsavedModalText"),
                    confirmButtonText: "components.beeEditor.saveAndExit",
                    dismissButtonText: "components.beeEditor.exitWithoutSaving",
                    type: "warning"
                });

                if (unsavedChangesPrompt === true) {
                    shouldExit.value = true;
                    await save();
                } else if (unsavedChangesPrompt === "dismiss") {
                    exit();
                }
            } else {
                exit();
            }
        };

        const togglePreview = () => {
            if (bee) {
                bee.togglePreview();
            }
        };

        const toggleStructure = () => {
            if (bee) {
                bee.toggleStructure();
            }
        };

        const toggleProductModal = () => {
            if (bee) {
                window.analytics.track("Product Modal opened sent", {
                    userId: store.getters["user/emailHash"],
                    templateTye: props.templateType
                });
                bee.loadRows();
            }
        };

        async function saveAsTemplate() {
            if (
                (await openDialog(AppConfirmation, {
                    title: t("components.beeEditor.saveAsTemplateHeader"),
                    message: t("components.beeEditor.saveAsTemplateMessage"),
                    confirmButtonText: "general.save"
                })) === true
            ) {
                if (bee) {
                    bee.saveAsTemplate();
                }
            }
        }

        const getContrastColor = (hexColor: string) => {
            const r = parseInt(hexColor.substring(1, 3), 16);
            const g = parseInt(hexColor.substring(3, 5), 16);
            const b = parseInt(hexColor.substring(5, 7), 16);
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;
            return yiq >= 128 ? "#000000" : "#FFFFFF";
        };

        const defaultAudience = store.getters["audiences/getDefault"]();
        const primaryBrandColor = computed((): string => {
            return defaultAudience.color ? `#${defaultAudience.color}` : "";
        });

        const customFont = computed((): CustomFont[] =>
            store.state.settings.list
                .filter(
                    (setting: SettingModel) => setting.key === "custom_font"
                )
                .map((setting: SettingModel) => setting.value as CustomFont)
        );

        const setToken = async () => {
            if (
                !token.access_token ||
                new Date(token[".expires"]).getTime() / 1000 -
                    Math.floor(Date.now() / 1000) <=
                    15
            ) {
                const tokenResponse = await axios.get("api/bee/token");
                token = tokenResponse.data.tokens as TokenModel;
            }
        };

        watch(
            () => props.templateContent,
            async () => {
                if (props.templateContent !== "") {
                    let templateContent = props.templateContent;

                    const autoSavedTemplate = localStorage.getItem(
                        props.templateKey
                    );
                    if (autoSavedTemplate) {
                        const autoSaveAction = await openDialog(
                            AppConfirmation,
                            {
                                title: t(
                                    "components.beeEditor.loadAutosavedTemplateHeader"
                                ),
                                message: t(
                                    "components.beeEditor.loadAutosavedTemplateText"
                                ),
                                confirmButtonText:
                                    "components.beeEditor.loadAutosavedTemplate",
                                dismissButtonText:
                                    "components.beeEditor.loadCurrentTemplate",
                                type: "warning"
                            }
                        );

                        if (autoSaveAction === true) {
                            templateContent = autoSavedTemplate;
                            hasChanges.value = true;
                        } else if (autoSaveAction === "dismiss") {
                            localStorage.removeItem(props.templateKey);
                        } else {
                            exit(false);
                            return;
                        }
                    }

                    document.body.classList.add("overflow-hidden");
                    editorKey.value++;
                    loaded.value = false;
                    show.value = true;

                    const config: IBeeConfig = {
                        uid: store.state.auth.beeEditorUid, //needed for identify resources of the that user and billing stuff
                        container: "bee-editor-container", //Identifies the id of div element that contains BEE Plugin
                        language:
                            localStorage.getItem("editorLocale") || undefined,
                        autosave: 15,
                        workspace: {
                            editSingleRow: props.singleRowMode
                        },
                        mergeTags: store.getters["buildertokens/getMergeTags"],
                        specialLinks:
                            store.getters["buildertokens/getSpecialLinks"],
                        contentDefaults: {
                            button: {
                                styles: {
                                    color: getContrastColor(
                                        primaryBrandColor.value
                                    ),
                                    backgroundColor: primaryBrandColor.value
                                }
                            },
                            general: {
                                defaultFont:
                                    customFont.value[0]?.fontFamily ?? ""
                            }
                        },
                        editorFonts: {
                            showDefaultFonts: true,
                            customFonts: [
                                ...(customFont.value.length
                                    ? customFont.value
                                    : []),
                                {
                                    name: "Roboto",
                                    fontFamily: "'Roboto', sans-serif",
                                    url:
                                        "https://fonts.googleapis.com/css?family=Roboto"
                                },
                                {
                                    name: "Open Sans",
                                    fontFamily: "'Open Sans', sans-serif",
                                    url:
                                        "https://fonts.googleapis.com/css?family=Open+Sans"
                                },
                                {
                                    name: "Lora",
                                    fontFamily: "'Lora', serif",
                                    url:
                                        "https://fonts.googleapis.com/css?family=Lora:i"
                                },
                                {
                                    name: "Chivo",
                                    fontFamily: "'Chivo', sans-serif",
                                    url:
                                        "https://fonts.googleapis.com/css?family=Chivo"
                                },
                                {
                                    name: "Raleway",
                                    fontFamily: "'Raleway', sans-serif",
                                    url:
                                        "https://fonts.googleapis.com/css2?family=Raleway"
                                }
                            ]
                        },
                        rowsConfiguration: {
                            emptyRows: true,
                            defaultRows: true,
                            externalContentURLs: [
                                {
                                    name: t(
                                        "components.mails.editContent.dynamicProductRows"
                                    ),
                                    value: `${store.state.auth.baseUrl}/bee/dynamic-product-rows`
                                },
                                {
                                    name: t(
                                        "components.mails.editContent.savedRows"
                                    ),
                                    value: `${store.state.auth.baseUrl}/bee/saved-rows`,
                                    handle: "saved-rows",
                                    // @ts-ignore
                                    behaviour: {
                                        canDelete: true,
                                        canEdit: true
                                    }
                                }
                            ]
                        },
                        contentDialog: {
                            saveRow: {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                // @ts-ignore
                                handler: async function(resolve, reject) {
                                    const saveRowName = await openDialog(
                                        SaveRowModal
                                    );
                                    if (saveRowName) {
                                        const metadata = {
                                            name: saveRowName,
                                            rowId: uuidv4()
                                        };
                                        resolve(metadata);
                                    } else {
                                        reject();
                                    }
                                }
                            },
                            onDeleteRow: {
                                handler: async (resolve, reject, args) => {
                                    // get the unique row id from metadata
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                    // @ts-ignore
                                    const rowId = args?.row?.metadata?.rowId;
                                    if (rowId) {
                                        axios
                                            .delete(`api/rows/${rowId}/delete`)
                                            .then(() => {
                                                toast.add({
                                                    severity: "success",
                                                    summary: "Row Delete",
                                                    detail: `Row has has been successfully deleted.`,
                                                    life: 3000
                                                });
                                                resolve(true);
                                            })
                                            .catch(() => {
                                                toast.add({
                                                    severity: "error",
                                                    summary:
                                                        "Saving row failed",
                                                    detail: `There was an error deleting this row. Please try again.`,
                                                    life: 3000
                                                });
                                                reject();
                                            });
                                    } else {
                                        reject();
                                    }
                                }
                            },
                            externalContentURLs: {
                                label: t("components.beeEditor.searchProducts"),
                                handler: async (resolve, reject) => {
                                    const selectedProducts = (await openDialog(
                                        AddProductsModal
                                    )) as number[];

                                    window.analytics.track(
                                        "Product Rows retrieved",
                                        {
                                            userId:
                                                store.getters["user/emailHash"],
                                            templateType: props.templateType
                                        }
                                    );

                                    if (
                                        selectedProducts !== null &&
                                        selectedProducts.length
                                    ) {
                                        let productIdsString = "";
                                        selectedProducts.forEach(
                                            (productId, index) => {
                                                if (index) {
                                                    productIdsString += "&";
                                                } else {
                                                    productIdsString += "?";
                                                }
                                                productIdsString += `productIds[]=${productId}`;
                                            }
                                        );
                                        const value = `${store.state.auth.baseUrl}/bee/product-rows${productIdsString}`;
                                        resolve({
                                            name: t(
                                                "components.beeEditor.selectedProducts"
                                            ), // Will be added as a new choice in the rows drop-down
                                            value // Will be used to get the list of rows
                                        });
                                    } else {
                                        reject();
                                    }
                                }
                            }
                        },
                        onLoad,
                        onAutoSave,
                        onChange,
                        onError,
                        onWarning,
                        onSave,
                        onSaveRow,
                        onSaveAsTemplate
                    };

                    await nextTick(async () => {
                        await setToken();
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        bee = new BeePlugin(token);
                        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                        // @ts-ignore
                        await bee.start(config, templateContent);
                    });
                }
            }
        );

        watch(
            () => props.templateName,
            () => {
                if (props.templateName) {
                    templateNameModel.value = props.templateName;
                }
            }
        );

        const resetTemplateName = () => {
            templateNameModel.value = props.templateName || "";
            activeItem.value = "";
        };

        const sendTestMailAction = async () => {
            if (props.emailId) {
                if (hasChanges.value) {
                    if (
                        (await openDialog(AppConfirmation, {
                            title: t("components.beeEditor.unsavedModalHeader"),
                            message: t(
                                "components.beeEditor.sendTestMailUnsavedModalText"
                            ),
                            confirmButtonText:
                                "components.beeEditor.sendTestMailProceed",
                            dismissButtonText:
                                "components.beeEditor.sendTestMailContinueEdit",
                            type: "warning"
                        })) === true
                    ) {
                        await save();
                    } else {
                        return;
                    }
                }

                await sendTestMail(props.emailId);
            }
        };

        const switchTemplate = async () => {
            const chosenTemplate: TemplateModel = await openDialog(
                ChooseTemplateModal,
                {
                    showEmptyTemplate: false
                }
            );
            if (chosenTemplate !== null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                bee.reload(chosenTemplate.content);
            }
        };

        return {
            show,
            editorKey,
            loaded,
            exitFromEditor,
            save,
            onSave,
            togglePreview,
            toggleStructure,
            activeItem,
            templateNameModel,
            resetTemplateName,
            saveAsTemplate,
            sendTestMailAction,
            toggleProductModal,
            switchTemplate
        };
    }
});
