
import { defineComponent } from "vue";
import AppModal from "@/components/common/AppModal.vue";
import AppButton from "@/components/common/AppButton.vue";
import AppIcon from "@/components/common/AppIcon.vue";
import { closeDialog } from "vue3-promise-dialog";

enum TypeEnum {
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info"
}

export default defineComponent({
    name: "AppConfirmation",
    components: {
        AppModal,
        AppButton,
        AppIcon
    },
    props: {
        title: {
            type: String,
            default: "Confirmation"
        },
        message: {
            type: String,
            default: "Are you sure?"
        },
        confirmButtonText: {
            type: String,
            default: "general.confirm"
        },
        dismissButtonText: {
            type: String,
            default: "general.cancel"
        },
        type: {
            type: String,
            default: "info",
            validator: (value: string) => {
                return Object.values(TypeEnum).includes(value as TypeEnum);
            }
        }
    },
    setup() {
        return {
            closeDialog
        };
    }
});
