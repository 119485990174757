import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-start items-center" }
const _hoisted_2 = { class: "text-lg font-medium mt-4" }
const _hoisted_3 = { class: "w-100" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex flex-col justify-start mt-4" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-3 w-full mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_input = _resolveComponent("app-input")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createBlock(_component_app_modal, {
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeDialog(null))),
    size: "w-128"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("components.sendTestMailModal.sendTestMailModalHeader")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "text-sm mt-4",
            innerHTML: 
                        _ctx.$t('components.sendTestMailModal.sendTestMailModalText')
                    
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_input, {
              label: "Email",
              modelValue: _ctx.sendTestMailRecipients,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendTestMailRecipients) = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_app_button, {
            "button-style": "secondary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeDialog(null))),
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("general.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_app_button, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDialog(_ctx.sendTestMailRecipients))),
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("components.sendTestMailModal.sendTestMailSend")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}